import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts/inner"
import SEO from "../components/seo"
import VideoReviews from "../components/modules/video-grid"
import FeaturedReview from "../components/modules/featured-review"
import AllReviews from "../components/modules/all-reviews"

export default ({ data }) => {
  const page = data.wpPage
  const robots = {
    name: `robots`,
    content: `${page.seo.metaRobotsNoindex}, ${page.seo.metaRobotsNofollow}`,
  }
  const { videoReviews, featuredReview, allReviews } = page.reviews
  return (
    <Layout highContrast>
      <SEO
        title={page.seo.title}
        description={page.seo.metaDesc}
        meta={[robots]}
        schema={page.seo.schema?.raw}
      />
      <VideoReviews videos={videoReviews} />
      <FeaturedReview review={featuredReview} />
      <AllReviews reviews={allReviews} />
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      seo {
        ...SEO
      }
      reviews {
        videoReviews {
          title
          subtitle
          videos {
            html
          }
        }
        featuredReview {
          title
          review
          floater {
            localFile {
              publicURL
            }
            altText
          }
        }
        allReviews {
          reviews {
            author
            body
          }
          title
        }
      }
    }
  }
`
