import React from "react"
import tw, { styled, css } from "twin.macro"
import BlockHeading from "./block-heading"
import { getImage } from "../../../util/functions"
import LazyLoad from "react-lazyload"
import StarsComp from "./stars"
import Content from "./content"

const Wrapper = styled.div(() => [
  tw`flex w-full flex-col pt-16 px-8 items-end text-white font-mont font-bold justify-center bg-marrone-black`,
  css`
    @media (max-width: 1024px) {
      ${tw`pb-12`}
    }
    @media (max-width: 640px) {
      ${tw`px-2`}
    }
  `,
])

const Review = styled.div(() => [
  tw`text-base shadow-lg font-normal text-black bg-white py-16 px-24 relative -mb-32`,
  css`
    max-width: 1250px;
    @media (max-width: 1024px) {
      ${tw`p-12`}
    }
    @media (max-width: 640px) {
      ${tw`p-4 py-8`}
    }
  `,
])

const Floater = styled.img(() => [
  tw`absolute top-0 left-0`,
  css`
    transform: translateX(-80%) translateY(-50%);
    width: 400px;
    z-index: 1;

    @media (max-width: 1440px) {
      transform: translateX(-60%) translateY(-50%);
    }

    @media (max-width: 1280px) {
      transform: translateY(-50%);
      top: -5rem;
    }

    @media (max-width: 1280px) {
      width: 300px;
      top: -5rem;
    }
  `,
])

const Heading = tw(BlockHeading)`w-full mb-12`
const Stars = tw(StarsComp)`z-10`

export default ({ review: fields }) => {
  const { title, review, floater } = fields
  return (
    <Wrapper>
      <Heading tw="z-10">{title}</Heading>
      <Review>
        {floater && (
          <LazyLoad height={200} once>
            <Floater
              src={getImage(floater?.localFile?.publicURL, true)}
              alt={floater?.altText}
            />
          </LazyLoad>
        )}
        <Stars />
        <Content>{review}</Content>
      </Review>
    </Wrapper>
  )
}
