import React from "react"
import tw from "twin.macro"

const Stars = tw.div`-ml-1 flex font-normal text-5xl text-marrone-primary`

export default props => (
  <Stars {...props}>
    {Array(5)
      .fill()
      .map((_, index) => (
        <span key={index}>&#9733;</span>
      ))}
  </Stars>
)
