import React from "react"
import tw, { css, styled } from "twin.macro"
import BlockHeading from "./block-heading"
import ReviewsList from "./reviews-list"

const Wrapper = styled.div(() => [
  tw`w-full flex items-center justify-center flex-col pb-64 pt-32 px-8`,
  css`
    @media (max-width: 1024px) {
      ${tw`pt-16 pb-0 px-4`}
    }
  `,
])

const Heading = tw(BlockHeading)`mt-24 mb-8 lg:mb-16`

export default ({ reviews: fields }) => {
  const { title, reviews } = fields
  return (
    <Wrapper>
      <Heading>{title}</Heading>
      <ReviewsList reviews={reviews} />
    </Wrapper>
  )
}
