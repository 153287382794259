import React from "react"
import tw, { styled, css } from "twin.macro"
import Content from "./content"
import Stars from "./stars"

const List = styled.ul(() => [
  tw`flex-col -my-12`,
  css`
    max-width: 1200px;
  `,
])
const Item = styled.li(({ rowSize }) => [tw`my-12`])
const Author = tw.p`text-marrone-primary font-bold mt-4`

export default ({ reviews }) => {
  const listReviews = reviews.map((review, index) => (
    <Item key={index}>
      <Stars tw="mb-2" />
      <Content>{review.body}</Content>
      <Author>- {review.author}</Author>
    </Item>
  ))
  return <List>{listReviews}</List>
}
